import './voaInfo.scss';
import useDesktopChecker from "hooks/useDesktopChecker";
import voaInfoImage from 'assets/images/voaInfoImage.svg';
import boltIconImage from 'assets/images/boltIconImage.svg';
import schieldIconImage from 'assets/images/schieldIconImage.svg';
import documentIconImage from 'assets/images/documentIconImage.svg';

const VoaInfo = () => {
    const isDesktop = useDesktopChecker();
    return (
        <div className='voaInfo-wrapper'>
            <h1>NAF uses <span>AccountChek</span> to connect your accounts</h1>
            <div className='info'>
                <div className='details-container'>
                    <div className='details'>
                        <img src={boltIconImage} alt='bullet icon'/>
                        <div className='point'>
                            <span>Connects in seconds</span>
                            <p>Quickly connect to any of your financial institutions</p>
                        </div>
                    </div>
                    <div className='details'>
                        <img src={schieldIconImage} alt='bullet icon'/>
                        <div className='point'>
                            <span>Keep your data safe</span>
                            <p>Uses best in class encryption to protect your data</p>
                        </div>
                    </div>
                    <div className='details'>
                        <img src={documentIconImage} alt='bullet icon'/>
                        <div className='point'>
                            <span>Eliminate paperwork</span>
                            <p>No forms, no hassle -- get verified</p>
                        </div>
                    </div>
                </div>
                {isDesktop && <img src={voaInfoImage} alt='account-check-image' />}
            </div>
        </div>)
}

export default VoaInfo;