import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import * as Constants from "utilities/Constant";

export const createAppInsights = () => {
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: Constants.APPINSIGHTS_CONNECTION_STRING,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      correlationHeaderExcludedDomains: ["*.queue.core.windows.net"],
    },
  });
  appInsights.loadAppInsights();
  return { reactPlugin, appInsights };
};

