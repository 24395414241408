import TableItems from "components/Table/Table";
import Card from "components/card/Card";
import {
  useGetDashboardItemsQuery,
  DashboardItemsResponseDto,
  useSetStatusFlagOfUserMutation,
  useSetPalStatusFlagOfUserMutation,
  useCheckCreditScoreQuery,
} from "./dashboardService";
import FundedState from "components/funded-state/FundedState";
import LoanProgessBar from "components/ui/loan-progress-bar/LoanProgessBar";
import { useSelector, useDispatch } from "react-redux";
import DashboardPopUp from "pages/dashboard-popup/Dashboard-popup";
import "./dashboard.scss";
import { getCurrentUserName, getCurrentUserId, setLogout, getNhDealApplicationId, getHasPAL, getProspectId } from "features/authSlice";
import { useNavigate } from "react-router";
import { showDocuments } from "utilities/UserversionFeatures";
import { useState } from "react";
import NafInsuranceAd from "../advertisements/NafInsurance";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import { DefaultConditionsCount } from "utilities/Constant";
import PreApprovalPreview from "./PalPreview/PreApprovalPreview";
import UqualBanner from "../advertisements/uqual-banner/UqualBanner";
import UqualModal from "../advertisements/uqual-modal/UqualModal";

export const tooltipIndexValues = {
  initial: 0,
  conditions: 1,
  milestones: 2,
  documents: 3,
  completed: 4,
};

export default function Dashboard() {
  const userName = useSelector(getCurrentUserName);
  const userId = useSelector(getCurrentUserId);
  const [isPalPreview, setIsPalPreview] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isError, error } = useGetDashboardItemsQuery(userId);
  const [tooltipIndex, setTooltipIndex] = useState(tooltipIndexValues.initial);

  const toggleModal = () => setIsPalPreview(!isPalPreview);
  let component: JSX.Element | null = null;
  if (isError && typeof error === "object" && "status" in error && error.status === 401) {
    dispatch(setLogout());
    navigate("/", { replace: true, state: { path: location.pathname } });
  }
  if (data == undefined) {
    return null;
  }
  const {
    loanStatus: { status, subStatus },
    closingDate,
  }: DashboardItemsResponseDto = data;

  const handleClickPal = () => {
    toggleModal();
    setTooltipIndex(tooltipIndexValues.completed);
  };

  const handleClosePal = () => {
    toggleModal();
    setTooltipIndex(tooltipIndexValues.conditions);
  };

  switch (status) {
    case "Processing":
    case "Underwriting":
    case "Approval":
    case "FinalReview":
    case "Close":
    default:
      component = (
        <OutStandingItemsCard
          tooltipIndex={tooltipIndex}
          setTooltipIndex={setTooltipIndex}
          isStatusInStarted={status == "Started"}
          handleClickPal={handleClickPal}
          isPalPreview={isPalPreview}
        />
      );
      break;
    case "Funded":
      component = <FundedState userName={userName.toUpperCase() + "!"} />;
      break;
  }
  return (
    <>
      {status != "Funded" && (
        <LoanProgessBar
          status={status}
          currentStatus={subStatus}
          closingDate={closingDate}
          showTooltip={tooltipIndex === tooltipIndexValues.milestones}
          tooltipIndex={tooltipIndex}
          setTooltipIndex={setTooltipIndex}
        />
      )}
      {component}
      {isPalPreview && <PreApprovalPreview onClose={handleClosePal} />}
    </>
  );
}

const OutStandingItemsCard = (props: {
  tooltipIndex: number;
  setTooltipIndex: (index: number) => void;
  isStatusInStarted: boolean;
  handleClickPal: () => void;
  isPalPreview?: boolean;
}) => {
  const { tooltipIndex, setTooltipIndex, isStatusInStarted, handleClickPal, isPalPreview = false } = props;
  const userId = useSelector(getCurrentUserId);
  const nhDealAppId = useSelector(getNhDealApplicationId);
  const hasPAL = useSelector(getHasPAL);
  const userName = useSelector(getCurrentUserName);
  const prospectId = useSelector(getProspectId);
  const { pendingItems, isUserNew, isGetItemError, getItemError, isPalViewed } = useGetDashboardItemsQuery(userId, {
    selectFromResult: ({ data, isError, error }) => ({
      pendingItems: data!.pendingItems,
      isUserNew: data!.isNew,
      isGetItemError: isError,
      getItemError: error,
      isPalViewed: data!.isPalViewed,
    }),
  });
  const { data: isCreditScoreLow } = useCheckCreditScoreQuery({nhDealApplicationId:nhDealAppId,prospectId:prospectId});
  const [updateUserstatus, { isError, error }] = useSetStatusFlagOfUserMutation();
  const [updatePalUserStatus] = useSetPalStatusFlagOfUserMutation();
  const { data: featureFlag } = useGetFeatureFlagsQuery();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (isGetItemError || isError) {
    if (typeof getItemError === "object" && "status" in getItemError && getItemError.status === 401) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    if (typeof error === "object" && "status" in error && error.status === 401) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
    // should add code for other errors
  }
  const handleClose = () => {
    if (hasPAL) {
      void updatePalUserStatus(nhDealAppId);
    } else {
      void updateUserstatus(userId);
    }
    setTooltipIndex(tooltipIndexValues.conditions);
  };

  const handleClick = () => {
    handleClose();
    handleClickPal();
  };

  const { data } = useGetDashboardItemsQuery(userId);
  const loanstatus = data?.loanStatus.status != "Started";
  const getConditionsTile = () => {
    return (
      <>
        <TableItems
          Tablevalues={pendingItems}
          showTooltip={tooltipIndex === tooltipIndexValues.conditions}
          setTooltipIndex={setTooltipIndex}
          isStatusInStarted={isStatusInStarted}
        />
        {featureFlag?.insuranceAdEnabled && !isCreditScoreLow && <NafInsuranceAd />}
      </>
    );
  };

  const [isShowUqualModal, setIsShowUqualModal] = useState(false);
  const handleUqualClick = () => {
    setIsShowUqualModal(true);
  };

  return (
    <>
      {isCreditScoreLow && (
        <div className="dashboard-uqal-banner">
          <UqualBanner onClick={handleUqualClick} />
        </div>
      )}
      {isCreditScoreLow && isShowUqualModal && <UqualModal onClose={() => setIsShowUqualModal(false)} />}
      <div className={loanstatus || pendingItems.length == DefaultConditionsCount ? "generic-condition" : "conditionsContainer"}>
        <Card header={tooltipIndex === tooltipIndexValues.conditions ? "" : "Tasks to be completed"} body={getConditionsTile()} />
      </div>
      {showDocuments() && (
        <div className="dashboard-content">
          <p className="dashboard-content__text">* All documents uploaded by you are visible under the My Documents tab</p>
        </div>
      )}
      {!isPalPreview && (isUserNew || (!isPalViewed && hasPAL && featureFlag?.preApprovalLetterEnabled)) && (
        <DashboardPopUp
          onClose={() => {
            handleClose();
          }}
          userName={userName.split(" ")[0]}
          showNewPalPopup={!isPalViewed && hasPAL}
          handleClick={handleClick}
        />
      )}
    </>
  );
};
