import { BrowserAgent } from "@newrelic/browser-agent/loaders/browser-agent";

export const initNewRelic = () => {
  if (window.env.NEW_RELIC_ENABLED) {
    // Populate using values in copy-paste JavaScript snippet.
    const options = {
      init: {
        distributed_tracing: {
          enabled: true,
          cors_use_newrelic_header: true,
          cors_use_tracecontext_headers: true,
          allowed_origins: window.env.NEW_RELIC_ALLOWED_ORIGINS.split(','),
        },
        privacy: { cookies_enabled: true },
        ajax: { deny_list: ["bam.nr-data.net"] },
      }, // NREUM.init
      info: { beacon: "bam.nr-data.net", errorBeacon: "bam.nr-data.net", licenseKey: window.env.NEW_RELIC_LICENSE_KEY, applicationID: window.env.NEW_RELIC_AGENT_ID_AND_APPLICATION_ID, sa: 1 }, // NREUM.info
      loader_config: {
        accountID:  window.env.NEW_RELIC_ACCOUNT_ID_AND_TRUST_KEY,
        trustKey: window.env.NEW_RELIC_ACCOUNT_ID_AND_TRUST_KEY,
        agentID: window.env.NEW_RELIC_AGENT_ID_AND_APPLICATION_ID,
        licenseKey: window.env.NEW_RELIC_LICENSE_KEY,
        applicationID: window.env.NEW_RELIC_AGENT_ID_AND_APPLICATION_ID,
      }, // NREUM.loader_config
    };

    // The agent loader code executes immediately on instantiation.
    new BrowserAgent(options);
  }
};
