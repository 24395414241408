import { Routes } from "components/routes/Routes";
import { AppInsightsContext, withAITracking } from "@microsoft/applicationinsights-react-js";
import { createAppInsights } from "app-insights/app-insights";
import "./App.scss";
import * as Constants from "utilities/Constant";

function App() {
  const newRelicEnabled = Constants.NEW_RELIC_ENABLED;
  if (!newRelicEnabled) {
    //tracking page view explicitly for single page application
    createAppInsights().appInsights.trackPageView();
    withAITracking(createAppInsights().reactPlugin, App, "appcomponent");

    return (
      <AppInsightsContext.Provider value={createAppInsights().reactPlugin}>
        <div className="App">
          <Routes />
        </div>
      </AppInsightsContext.Provider>
    );
  } else {
    return (
      <div className="App">
        <Routes />
      </div>
    );
  }
}
export default App;
