import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getStepData } from "components/question/questionSlice";
import "./navbarDropDown.scss";
import HeaderMenu from "../header-menu/HeaderMenu";
import { useSelector } from "react-redux";
import useDesktopChecker from "hooks/useDesktopChecker";
import { INavBarProps, NavLinks } from "./NavBarUtilities";
import { useGetNotificationDetailsQuery } from "pages/authentication/userService";
import { getCurrentUserId } from "features/authSlice";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import NotificationPopup from "components/notification-popup/NotificationPopup";
import newNotificationDesktop from "assets/images/notification_new_desktop.svg";
import notificationDesktop from "assets/images/notification_desktop.svg";
import newNotificationMobile from "assets/images/notification_new_mob.svg";
import notificationMobile from "assets/images/notification_mob.svg";
import { useGetDashboardItemsQuery } from "pages/dashboard/home/dashboardService";

export default function NavbarDropDown({ userName, showNavLinks = false, showMyLoans, showPreferences }: Readonly<INavBarProps>) {
  const navRef = useRef<HTMLElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  const showNavbar = () => {
    setShowMenu(!showMenu);
  };
  const isDesktop = useDesktopChecker();
  const stepData = useSelector(getStepData);
  const isSummary = stepData?.stepType === "summary";
  const isHideMyApp = location.pathname === "/my-loans" || location.pathname === "/path";
  const [showNotificationPopupComponent, setshowNotificationPopupComponent] = useState(false);
  const userId = useSelector(getCurrentUserId);
  const { data } = useGetDashboardItemsQuery(userId);
  const loanstatus = data?.loanStatus.status;
  const isLoanStatusFunded = loanstatus == "Funded";
  const notificationIconRef = useRef<HTMLDivElement>(null);
  
  const { data: notificationData } = useGetNotificationDetailsQuery(userId, {
    skip: !featureFlag?.preApprovalLetterEnabled,
  });
  const [notifications, setNotifications] = useState(notificationData);
  // Update state and log notifications whenever notificationData changes
  useEffect(() => {
    if (notificationData) {
      setNotifications(notificationData);
    }
  }, [notificationData]);

  useEffect(() => {
    const handleDocumentClick = (event: MouseEvent) => {
      if (notificationIconRef.current && !notificationIconRef.current.contains(event.target as Node)) {
        setshowNotificationPopupComponent(showNotificationPopupComponent);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const isYetToViewNotification = notifications ? notifications?.length !== 0 || !notifications: false;
  const handleIconClick = () => {
    setshowNotificationPopupComponent((prevState) => !prevState);
  };
 
  return (
    <div className="navbar-container">
      {isDesktop ? (
        <nav ref={navRef} className="navbar">
          {!isSummary && showNavLinks && <NavLinks />}
          <div className="navbar-right">
            {featureFlag?.preApprovalLetterEnabled && !isLoanStatusFunded && (
              <>
              <div className={`bell-icon ${isYetToViewNotification ? "pointer-on-hover":""}`} ref={notificationIconRef}>
                {isYetToViewNotification ? (
                  <img src={newNotificationDesktop}  alt="newNotificationDesktop" onClick={handleIconClick} />
                ) : (
                  <img src={notificationDesktop} alt="notificationDesktop" onClick={handleIconClick} />
                )}
              </div>
                {showNotificationPopupComponent && isYetToViewNotification && 
                <NotificationPopup notifications={notifications} setNotifications={setNotifications} />}  
                </>
            )}
            {!isHideMyApp && showMyLoans && (
              <>
                <NavLink to="/my-loans" className="menu-link" end>
                  My Loans
                </NavLink>
                <span className="menu-link-splitter">|</span>
              </>
            )}

            {userName && (
              <HeaderMenu name={userName} showMyLoans={showMyLoans ?? false} showNavbar={showNavbar} showPreferences={showPreferences ?? false} />
            )}
            <span onClick={showNavbar} className="navbar__btn navbar__close-btn">
              <FontAwesomeIcon icon={regular("xmark")} />
            </span>
          </div>
        </nav>
      ) : (
        <>
          {showMenu && (
            <nav ref={navRef} className="navbar">
              <HeaderMenu onShow={showMenu} showMyLoans={showMyLoans ?? false} showNavbar={showNavbar} showPreferences={showPreferences ?? false} />
              <span onClick={showNavbar} className="navbar__btn navbar__close-btn">
                <FontAwesomeIcon icon={regular("xmark")} />
              </span>
            </nav>
          )}
        </>
      )}

      {!isDesktop && (
        <div className="navbar__block">
          {featureFlag?.preApprovalLetterEnabled && !isLoanStatusFunded && (
            <>
            <div className={`bell-icon ${isYetToViewNotification ? "pointer-on-hover": ""}`} ref={notificationIconRef}>
              {isYetToViewNotification ? (
                <img src={newNotificationMobile} alt="newNotificationMobile" onClick={handleIconClick} />
              ) : (
                <img src={notificationMobile} alt="notificationMobile" onClick={handleIconClick} />
              )}
            </div>
              {showNotificationPopupComponent && isYetToViewNotification && 
            <NotificationPopup notifications={notifications} setNotifications={setNotifications} />}
            </>
          )}
          <span onClick={showNavbar} className="navbar__btn">
            <FontAwesomeIcon icon={regular("bars")} />
          </span>
        </div>
      )}
    </div>
  );
}
