import { Button } from "components/ui/button/Button";
import "./welcome-v-one.scss";
import { ReactComponent as WelcomeGraphicDesktop } from "assets/images/WelcomeGraphicDesktop.svg";
import { ReactComponent as WelcomeGraphicMobile } from "assets/images/WelcomeGraphicMobile.svg";
import { useCreateApplicationMutation, CreateApplicationTemplateRequest } from "pages/welcome/welcomePathService";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUserId, setApplicationId, setLogout, getVersion, getLeadProvider } from "features/authSlice";
import { UserVersion, PathType } from "utilities/UserversionFeatures";
import Footer from "components/footer/Footer";
import useDesktopChecker from "hooks/useDesktopChecker";
import { LeadProvider } from "pages/registration/registrationService";

export default function WelcomeVersonOne() {
  const [
    CreateApplication,
    { data, isSuccess, isError: isCreateApplicationError, isLoading: createApplicationLoading, error: createApplicationError },
  ] = useCreateApplicationMutation();
  const isDesktop = useDesktopChecker();
  const navigate = useNavigate();
  const location = useLocation();
  const version = useSelector(getVersion);
  const userId = useSelector(getCurrentUserId);
  const dispatch = useDispatch();
  const leadProvider = useSelector(getLeadProvider);
  const isLPMicrosite = () => leadProvider.toLowerCase() == LeadProvider.Microsite.toLowerCase();

  const handleOnSubmit = async () => {
    const RequestBody: CreateApplicationTemplateRequest = {
      userId: userId,
      pathType: pathType(version.toUpperCase()),
    };
    await CreateApplication(RequestBody);
  };
  if (isSuccess && data) {
    dispatch(setApplicationId(data));
    navigate("/application", { replace: true });
  } else if (isCreateApplicationError) {
    if (typeof createApplicationError === "object" && "status" in createApplicationError && createApplicationError.status === 401) {
      dispatch(setLogout());
      navigate("/", { replace: true, state: { path: location.pathname } });
    }
  }

  const pathType = (version: string) => {
    if (isLPMicrosite()) return PathType.FULLPATH;
    switch (version) {
      case UserVersion.VERSION_1:
        return PathType.LIGHTNINGPATH;
      case UserVersion.VERSION_2:
        return PathType.LIGHTNINGPATHV2;
      case UserVersion.VERSION_3:
        return PathType.LIGHTNINGPATHV3;
      default:
        return PathType.LIGHTNINGPATHV3;
    }
  };

  if (createApplicationLoading) return <LoadingOverlay />;
  return (
    <div className="welcome-page">
      <div className="welcome-page_title">Easy. Secure. Reliable.</div>
      <div className="welcome-page_description">
        <p className="welcome-page_description__one">
          Learning about your mortgage options can be easier with a trusted partner by your side. Please take a moment to answer a few simple
          questions in our secure platform so we can help provide you all the information you will need.
        </p>
        {isLPMicrosite() ?? (
          <p className="welcome-page_description__two">
            If you have already provided some of this information, please review to make sure all your information is correct.
          </p>
        )}
      </div>
      <Button title="Get Started" onClick={() => void handleOnSubmit()} />
      <div className="welcome-page_graphic">{isDesktop ? <WelcomeGraphicDesktop /> : <WelcomeGraphicMobile />}</div>
      <Footer />
    </div>
  );
}
