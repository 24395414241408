import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./mfa.scss";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthDevicesDetails,
  setResendCodeDeviceId,
} from "pages/login/loginSlice";
import { useRedirect } from "hooks/useRedirect";
import { usePostMfaMutation } from "./mfaService";
import { useNavigate } from "react-router-dom";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";

const Mfa = () => {
  const { flowId: MfaFlowId, _embedded: deviceDetailsList } = useSelector(
    getAuthDevicesDetails
  );
  const { devices } = deviceDetailsList;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useRedirect(MfaFlowId == "", "/");

  const emailAuthDetails = devices.find(
    (obj) => obj.type.toLowerCase() == "email"
  );
  const smsAuthDetails = devices.find((obj) => obj.type.toLowerCase() == "sms");

  const [sendRequest, { data, isSuccess, isLoading }] = usePostMfaMutation();

  if (data && isSuccess) {
    navigate("/authcode", { replace: true });
  }

  const handleClick = (deviceId: string) => {
    dispatch(setResendCodeDeviceId(deviceId));
    return sendRequest({
      id: deviceId,
      flowId: MfaFlowId,
    });
  };

  if (isLoading) return <Overlay />;

  return (
    <div className="mfa-container">
      <p className="mfa-container__header">2-Step Verification</p>
      <div
        className="box-container"
        onClick={async () => await handleClick(smsAuthDetails?.id ?? "")}
        onKeyUp={async () => await handleClick(smsAuthDetails?.id ?? "")}
      >
        <FontAwesomeIcon
          icon={regular("mobile-retro")}
          className="icon-mobile"
        />
        <div className="desc">
          <p className="desc__header">Phone number verification</p>
          <p className="desc__message">Send code to <br /> number ending in {smsAuthDetails?.phone?.slice(-4)}</p>
        </div>
        <div className="arrow-right">
        <FontAwesomeIcon
          icon={regular("chevron-right")}
        />
        </div>
      </div>
      <div
        className="box-container"
        onClick={async () => await handleClick(emailAuthDetails?.id ?? "")}
        onKeyUp={async () => await handleClick(emailAuthDetails?.id ?? "")}
      >
        <FontAwesomeIcon
          icon={regular("envelope")}
          className="icon-envelope"
        />
        <div className="desc">
          <p className="desc__header">Email verification</p>
          <p className="desc__message">Send code to <br />{emailAuthDetails?.email}</p>
        </div>
        <div className="arrow-right">
        <FontAwesomeIcon
          icon={regular("chevron-right")}
        />
        </div>
      </div>
    </div>
  );
};
export default Mfa;
