import React from "react";
import { FormInput } from "components/ui/form/FormInput";
import { Button } from "components/ui/button/Button";
import homeGraphic from "assets/images/HomeGraphic.png";
import useDesktopChecker from "hooks/useDesktopChecker";
import { passwordIcon, passwordType } from "components/password-and-confirm-password/passwordcheckerversion2/PasswordCheckerVersion2";
import Form from "components/ui/form/Form";
import { FieldErrorsImpl, FieldValues, UseFormHandleSubmit, UseFormRegister } from "react-hook-form";
import "./login.scss";
import { TERMSOFUSELINK } from "utilities/Constant";
import { DecryptedUser, LeadProvider } from "pages/registration/registrationService";

interface NewLoginUIProps {
  readonly isLoginError: boolean;
  readonly loginErrorMessage: (error: number) => JSX.Element;
  readonly handleSubmit: UseFormHandleSubmit<FieldValues>;
  readonly register: UseFormRegister<FieldValues>;
  readonly error: number;
  readonly errors: Partial<FieldErrorsImpl<{ [x: string]: any }>>;
  readonly onSubmit: (data: { emailaddress: string; password: string }) => void;
  readonly isPasswordVisible: boolean;
  readonly setIsPasswordVisible: React.Dispatch<React.SetStateAction<boolean>>;
  readonly userDetails:DecryptedUser 
}

export default function NewLoginUI({
  isLoginError,
  loginErrorMessage,
  handleSubmit,
  register,
  errors,
  onSubmit,
  isPasswordVisible,
  setIsPasswordVisible,
  error,
  userDetails
}: NewLoginUIProps) {
  const isDesktop = useDesktopChecker();
  
 const generateUserGreeting = (userDetails:DecryptedUser, isDesktop:boolean) => {
    if (!userDetails || userDetails?.hp) {
      return "Welcome Back";
    }
    
    const lpn = userDetails.lpn?.toLowerCase();
    const isEligibleLeadProvider =
      lpn === LeadProvider.DigitalLeads.toLowerCase() ||
      lpn === LeadProvider.Microsite.toLowerCase() ||
      userDetails.id != null;

      if(isDesktop && isEligibleLeadProvider){
        return "Sign in to continue your application"
      }
      if(!isDesktop && isEligibleLeadProvider){
        return "Sign in to continue";
      }
    return "Welcome Back";   
  };
  return (
    <div className="login-form">
      <div className="login-form__container">
        <div className="login-form__content">
        <h1 className="login-form__header">{generateUserGreeting(userDetails,isDesktop)}</h1>
          {isLoginError && loginErrorMessage(error)}
          <div className="login-form__fields">
            <Form
              onSubmit={handleSubmit((data) => {
                onSubmit({
                  emailaddress: data.email as string,
                  password: data.password as string,
                });
              })}
            >
              <FormInput
                formHandle={register("email")}
                inputProps={{
                  name: "email",
                  placeholder: "Enter email",
                  label: "Email Address",
                  errorMessage: errors.email?.message as string,
                }}
              />
              <FormInput
                formHandle={register("password")}
                inputProps={{
                  name: "password",
                  type: passwordType(isPasswordVisible),
                  placeholder: "Enter password",
                  icon: passwordIcon(isPasswordVisible),
                  onIconClick: () => setIsPasswordVisible(!isPasswordVisible),
                  isIconPointer: true,
                  label: "Password",
                  errorMessage: errors.password?.message as string,
                }}
              />
              <Button title="Continue" className="login-btn" />
            </Form>
          </div>
          <div className="login-form__footer">
            <a href="/forgotpassword" className="forgot-password">
              Forgot Password?
            </a>
            <span>
              By continuing, you agree to our {""}
              <a href={TERMSOFUSELINK} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
            </span>
          </div>
        </div>
        <div className="login-form__image">{isDesktop && <img src={homeGraphic} alt="login-graphic" />}</div>
      </div>
    </div>
  );
}
