import { Button } from "components/ui/button/Button";
import "./terms-and-condition/terms-and-conditions.scss";
import Checkbox from "components/ui/checkbox/Checkbox";
import { useSelector } from "react-redux";
import { getStepData } from "components/question/questionSlice";
import { getCurrentUserApplicationId } from "features/authSlice";
import { useSetApplicationStepAnswerMutation } from "pages/application/applicationService";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/images/xmark.svg";
import {
  useCreateAssetVerificationOrderMutation,
  CreateAssetVerificationOrder,
} from "pages/asset-verification/assetVerificationService";
import Modal from "components/modal/Modal";
import { VOA_TOS_LINK } from "utilities/Constant";
import { SsoIframe } from "./SsoIframe";

export const AssetTOSModal = ({
  showAssetModal,
  toggleAssetModal,
  onAssetModalClose,
  handleAssetModalError,
}: {
  showAssetModal: boolean;
  toggleAssetModal: () => void;
  onAssetModalClose: () => void;
  handleAssetModalError: (error: boolean) => void;
}) => {
  const stepData = useSelector(getStepData);
  const { nextStepActions: stepActions } = stepData;
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(false);

  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);

  const userApplicationData: CreateAssetVerificationOrder = {
    email: "testNHVOAUser@example.com",
    referenceNumber: applicationId,
    consentTS: new Date().toISOString(),
  };
  const handleSubmit = async (stepActionId: string) => {
    await updateApplication({
      id: applicationId,
      stepActionId,
    });
  };

  const handleCloseIframe = () => {
    toggleModal();
    void handleSubmit(stepActions[0].stepActionId);
  };

  const [
    CreateAssetVerificationOrder,
    { isLoading, isSuccess, isError, data },
  ] = useCreateAssetVerificationOrderMutation();
  let orderId = "";
  const [show, setShow] = useState<boolean>(false);
  const toggleModal = () => setShow(!show);
  const onCreateOrder = async () => {
    toggleAssetModal();
    await CreateAssetVerificationOrder(userApplicationData);
    toggleModal();
  };
  const checkError = () => error && !isError;

  const handleError = (isError: boolean) => {
    if (checkError()) {
      setError(false);
      handleAssetModalError(false);
    } else if (!error && isError) {
      setError(true);
      handleAssetModalError(true);
    }
  };

  if (isSuccess && data) {
    orderId = data.id;
  }
  if (isLoading) {
    return <LoadingOverlay />;
  } else {
    handleError(isError);
  }
  if (isSuccess && orderId && show) {
    return (
      <SsoIframe
        orderId={orderId}
        showModal={show}
        toggleModal={toggleModal}
        handleError={handleError}
        onClose={handleCloseIframe}
      />
    );
  }

  return (
    <div className="toc">
      {showAssetModal && (
        <Modal
          customHeader={
            <div className="modal__close-container">
              <button
                className="modal__close-button"
                onClick={onAssetModalClose}
                aria-label="Close"
              >
                <CloseIcon />
              </button>
            </div>
          }
          customBody={
            <div className="toc-container">
              <div className="toc-container__body">
                <div className="toc-container__body--checkbox">
                  <Checkbox
                    onChange={(checked: boolean) => setIsChecked(checked)}
                    checkboxState={isChecked}
                  />
                  <span className="toc-container__body--checkbox__text">
                    Check here to agree to terms below.
                  </span>
                </div>
                <span className="toc-container__body--text1">
                  I agree to the{" "}
                  <a href={VOA_TOS_LINK} target="_blank" rel="noreferrer">
                    Terms of Service
                  </a>{" "}
                  and request that AccountChek® act as an intermediary on my
                  behalf to furnish information to prospective creditor in order
                  to further my application.
                </span>
              </div>
            </div>
          }
          footer={
            <div className="toc-container">
              <div className="toc-container__button">
                <Button
                  className={`toc-container__button--btn1`}
                  title="Continue"
                  type="round"
                  variant="primary"
                  onClick={() => void onCreateOrder()}
                  disabled={!isChecked}
                />
                <Button
                  className="toc-container__button--btn2"
                  title="Cancel"
                  type="round"
                  variant="outline-primary"
                  onClick={() => onAssetModalClose()}
                />
              </div>
            </div>
          }
        />
      )}
    </div>
  );
};
