import { Button } from "components/ui/button/Button";
import "./terms-and-condition/terms-and-conditions.scss";
import { useSelector } from "react-redux";
import { getStepData } from "components/question/questionSlice";
import { getCurrentUserApplicationId } from "features/authSlice";
import { useSetApplicationStepAnswerMutation } from "pages/application/applicationService";
import { useState } from "react";
import { SkipButton } from "pages/application/question/Question";
import VoaInfo from "pages/application/voa/VoaInfo";
import { AssetTOSModal } from "./AssetTOSModal";

export default function AssetVerification() {
  const stepData = useSelector(getStepData);
  const { nextStepActions: stepActions } = stepData;
  const skipAction = stepData.skipAction;
  const [error, setError] = useState(false);

  const [updateApplication] = useSetApplicationStepAnswerMutation({
    fixedCacheKey: "updateApplication",
  });
  const applicationId = useSelector(getCurrentUserApplicationId);

  const handleSubmit = async (stepActionId: string) => {
    await updateApplication({
      id: applicationId,
      stepActionId,
    });
  };

  const [showAssetModal, setShowAssetModal] = useState<boolean>(false);
  const onAssetModalClose = () => {
    toggleAssetModal();
  };
  const toggleAssetModal = () => setShowAssetModal(!showAssetModal);
  return (
    <div className="toc">
      {error && <div className="toc-error">AccountChek is unavailable at this time. Please enter your assets manually.</div>}
      <div className="toc-container1">
        <div className="toc-options">
          <div className="toc-popup">
            <AssetTOSModal
              showAssetModal={showAssetModal}
              toggleAssetModal={toggleAssetModal}
              onAssetModalClose={onAssetModalClose}
              handleAssetModalError={(e) => setError(e)}
            />
          </div>
          <div className="toc-container">
            <div>
              <div className="toc-container__button">
                <Button
                  className={`toc-container__button--btn1 ${showAssetModal ? "active" : ""}`}
                  title={stepActions[0].label}
                  type="round"
                  variant="primary"
                  onClick={() => toggleAssetModal()}
                />
                <Button
                  className="toc-container__button--btn2"
                  title={stepActions[1].label}
                  type="round"
                  variant="outline-primary"
                  onClick={() => handleSubmit(stepActions[1].stepActionId)}
                />
              </div>
            </div>
          </div>
          {skipAction && <SkipButton skipAction={skipAction} />}
        </div>
        <VoaInfo />
      </div>
    </div>
  );
}
