import * as Constants from "utilities/Constant";
import "./consent.scss";

export interface ConsentProps {
  partnersLink?: string;
  termsOfUseLink: string;
  privacyLink: string;
  agreementLink: string;
}

export const Consent = ({ privacyLink, termsOfUseLink, agreementLink }: ConsentProps) => {
  return (
    <div className="consent">
      <div className="consent__wrapper">
        <p className="consent--item">
          {Constants.Legal_Information_One}
          <a href={privacyLink} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          {Constants.Legal_Information_Two}
          {Constants.Legal_Information_Three}
          <a href={termsOfUseLink} target="_blank" rel="noreferrer">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href={agreementLink} target="_blank" rel="noreferrer">
            Electronic Econsent Agreement
          </a>
          {". "}
        </p>
        <p className="consent--reader">{Constants.Assistant_Note}</p>
        <p className="consent--reader">
          {Constants.Policy_Note}{" "}
          <a href={privacyLink} target="_blank" rel="noreferrer">
            Privacy Policy.
          </a>
        </p>
      </div>
    </div>
  );
};
