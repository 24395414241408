import { Loader } from "components/Loader/Loader";
import useDesktopChecker from "hooks/useDesktopChecker";
import {
  EXTERNAL_REDIRECTION_TOKEN,
  EXTERNAL_REDIRECTION_TYPE_INSURANCE,
  NafInsurance_Loader_Header_desktop,
  NafInsurance_Loader_Header_Mobile_Part1,
  NafInsurance_Loader_Header_Mobile_Part2,
  NafInsuranceAd_Loader_Message1,
  NafInsuranceAd_Loader_Message2,
} from "utilities/Constant";
import "./external.scss";
import { useEffect } from "react";

export default function External() {
  const redirectionType = localStorage.getItem(EXTERNAL_REDIRECTION_TOKEN);

  useEffect(() => {
    if (redirectionType) {
      localStorage.removeItem(EXTERNAL_REDIRECTION_TOKEN); //remove the token once processed
    }
  }, [redirectionType]);

  const isDesktop = useDesktopChecker();

  return (
    <div className="external-navigation">
      {" "}
      {redirectionType == EXTERNAL_REDIRECTION_TYPE_INSURANCE && (
        <Loader
          header={
            isDesktop ? (
              <>{NafInsurance_Loader_Header_desktop}</>
            ) : (
              <>
                {NafInsurance_Loader_Header_Mobile_Part1} <br />
                {NafInsurance_Loader_Header_Mobile_Part2}
              </>
            )
          }
          message1={NafInsuranceAd_Loader_Message1}
          message2={NafInsuranceAd_Loader_Message2}
        />
      )}
    </div>
  );
}
