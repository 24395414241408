import { useSelector } from "react-redux";
import { getLeadProvider, getCurrentUserApplicationId } from "features/authSlice";
import { LeadProvider } from "pages/registration/registrationService";
import WelcomeVersonOne from "./WelcomeVOne";
import WelcomeMultiPath from "./WelcomeMultiPath";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export default function Welcome() {
  const leadProvider = useSelector(getLeadProvider);
  const isLPMicrosite = () => leadProvider.toLowerCase() == LeadProvider.Microsite.toLowerCase();
  const applicationId = useSelector(getCurrentUserApplicationId);
  const navigate = useNavigate();
  useEffect(() => {
    if (applicationId) {
      navigate("/application", { replace: true });
    }
  }, [applicationId]);

  return <>{isLPMicrosite() ? <WelcomeVersonOne /> : <WelcomeMultiPath />}</>;
}
