import { useState, useEffect } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import PasswordChecklist from "react-password-checklist";
import { ReactComponent as CheckImg } from "assets/images/check.svg";
import { ReactComponent as Xmark } from "assets/images/xmark.svg";
import { Input } from "components/ui/input/Input";
import * as Constant from "utilities/Constant";
import "../password_checker.scss";

export const passwordIcon = (isPasswordVisible: boolean) => (isPasswordVisible ? solid("eye") : solid("eye-slash"));
export const passwordType = (isPasswordVisible: boolean) => (isPasswordVisible ? "text" : "password");

interface PasswordCheckerProps {
  checkErrors?: boolean;
  onChange?: (...event: any[]) => void;
  createLabel?: string;
  setHasPasswordError?: (hasError: boolean) => void;
  setBtnVisible?: (btnIsValid: boolean) => void;
  createPasswordPlaceholderName?: string;
}

const PasswordCheckerVersion2 = ({
  checkErrors = true,
  onChange,
  createLabel,
  setHasPasswordError,
  setBtnVisible: setBtnIsValid,
  createPasswordPlaceholderName = "Password",
}: PasswordCheckerProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [passwordCheckerError, setPasswordCheckerError] = useState("");
  const [checkPasswordError, setCheckPasswordError] = useState({ password: false });
  const [password, setPassword] = useState<string>("");
  const [isValid, setIsValid] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (checkErrors) {
      if (setHasPasswordError) {
        setHasPasswordError(passwordCheckerError !== "");
      }
      if (setBtnIsValid) {
        setBtnIsValid(!isValid);
      }
    }
  }, [checkErrors, password, passwordCheckerError, setHasPasswordError, setBtnIsValid]);

  const passwordChecklistStyle = isValid ? " password-checkerv2--validFalse" : " password-checkerv2--validTrue";

  return (
    <div className="password-input-checker">
      <Input
        name="password_input"
        type={passwordType(isPasswordVisible)}
        placeholder={createPasswordPlaceholderName}
        icon={passwordIcon(isPasswordVisible)}
        label={createLabel}
        containerClassName
        onIconClick={() => setIsPasswordVisible(!isPasswordVisible)}
        onChange={(e: React.FormEvent<HTMLInputElement>) => {
          setPassword(e.currentTarget.value);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={(e: React.FormEvent<HTMLInputElement>) => {
          setCheckPasswordError((props) => ({
            ...props,
            password: true,
          }));
        }}
        isIconPointer
        inputBorderHighlightForError={checkPasswordError.password && !isValid}
        onFocus={() => setIsFocus(true)}
      />
      {isFocus && (
        <div className={"input__wrapper no-margin-top password-checkerv2" + passwordChecklistStyle}>
          Password must include:
          <PasswordChecklist
            rules={["minLength", "capital", "lowercase", "number", "specialChar"]}
            minLength={Constant.PWD_MIN_LENGTH}
            value={password}
            messages={{
              minLength: Constant.NEW_PWD_CHECK_MIN_LENGTH,
              capital: Constant.NEW_PWD_CHECK_UPPER_CASE,
              lowercase: Constant.NEW_PWD_CHECK_LOWER_CASE,
              number: Constant.NEW_PWD_CHECK_NUMBER,
              specialChar: Constant.NEW_PWD_CHECK_SPECIAL_CHAR,
            }}
            onChange={(isValid) => {
              setIsValid(isValid);
              setPasswordCheckerError(isValid ? "" : Constant.INVALID_PASSWORD_ERROR);
            }}
            iconComponents={{
              ValidIcon: <CheckImg className="validCheck margin-small" />,
              InvalidIcon: <Xmark className="invalidMark margin-small" />,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PasswordCheckerVersion2;
