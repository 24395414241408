import { useEffect } from "react";
import { useGetSsoUrlQuery } from "pages/asset-verification/assetVerificationService";
import { LoadingOverlay } from "components/ui/loading-spinners";
import Modal from "components/modal/Modal";
import "./sso-iframe.scss";

export const SsoIframe = ({
  orderId,
  showModal,
  toggleModal,
  onClose,
  handleError,
}: {
  orderId: string;
  showModal: boolean;
  toggleModal: () => void;
  onClose: () => void;
  handleError: (isError: boolean) => void;
}) => {
  const { data, isSuccess, isLoading } = useGetSsoUrlQuery(orderId);
  useEffect(() => {
    const handleCloseFrame = (event: MessageEvent) => {
      if (event.data == "closeFrameTimeout") {
        toggleModal();
        handleError(true);
        window.removeEventListener("message", handleCloseFrame);
      } else if (event.data == "closeFrame") {
        onClose();
      }
    };
    window.addEventListener("message", handleCloseFrame);
    return () => window.removeEventListener("message", handleCloseFrame);
  }, []);
  return (
    <div className="voa-popup">
      {showModal && (
        <Modal
          customBody={
            <>
              {isLoading && <LoadingOverlay />}
              {isSuccess && <iframe src={data.url}></iframe>}
            </>
          }
        />
      )}
    </div>
  );
};
