import { Button } from "components/ui/button/Button";
import "./uqual-banner.scss";
import { UqualBannerProps } from "./uqualBannerProps";

export default function UqualBanner({ onClick }: Readonly<UqualBannerProps>) {
  return (
    <div className="uqual-container">
      <div className="uqual-container__title">Improve your credit with Uqual!</div>
      <div className="uqual-container__content">Raising your credit score can help you qualify for a mortgage and save thousands.</div>
      <div className="uqual-container__btn">
        <Button title="Start Now" size="small" onClick={onClick} />
      </div>
    </div>
  );
}
