import { useState } from "react";

import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./privacy-policy.scss";
import { useGetFeatureFlagsQuery } from "services/common/featureFlagService";
import useDesktopChecker from "hooks/useDesktopChecker";
export interface PrivacyPolicyProps {
  privacyLink: string;
}

export const PrivacyPolicy = ({ privacyLink }: PrivacyPolicyProps) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(true);
  const { data: featureFlag } = useGetFeatureFlagsQuery();
  const isLogin = location.pathname === "/" && featureFlag?.newLoginUIEnabled;
  const isDesktop = useDesktopChecker();
  const isLoginAndNotDesktop = isLogin && !isDesktop;
  return (
    <>
      {showPrivacyPolicy ? (
        <div className={`${isLogin ? "" : "consent__reader--wrapper"}`}>
          <p className={` ${isLogin ? "text--login-page" : ""}`}>
            By using our site, you agree to our use of {isLoginAndNotDesktop && <br />} cookies. For more information, read our{" "}
            {isLoginAndNotDesktop && <br />}
            <a href={privacyLink} target="_blank" rel="noreferrer" className={`privacy-link ${isLogin ? "text--login-page__link" : ""}`}>
              Privacy Policy.
            </a>
          </p>
          {!isLogin && (
            <span
              className="close-banner"
              onClick={() => {
                setShowPrivacyPolicy(false);
              }}
            >
              <FontAwesomeIcon icon={regular("circle-xmark")} />
            </span>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
