import "./notification-popup.scss";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction } from "react";
import { NotificationResponse } from "pages/authentication/userService";
import { calculateNotificationDuration } from "utilities/notificationduration";
interface NotsProps {
  notifications: NotificationResponse[] | undefined; 
 setNotifications: Dispatch<SetStateAction<NotificationResponse[] | undefined>>;

}
interface NotifyPopupProps {
  key: string | number;
  id: string;
  title: string | undefined;
  text: string | undefined;
  link: string;
  createdOn:Date;
  onRemoveViewedNotification: (id: string) => void;
}
const NotificationDetail = ({ key, title, text, link, id, createdOn, onRemoveViewedNotification }: NotifyPopupProps) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`${link}&Id=${id}`);
    onRemoveViewedNotification(id); // Remove the notification on click
  };
  return (
    <div className="notifiy-popup-wrapper" key={key}>
      <span className="notifiy-popup-wrapper__section">{title}</span>
      <span className="notifiy-popup-wrapper__name">{text}</span>
      <div className="notifiy-popup-wrapper__navigation">
        <span>{calculateNotificationDuration(createdOn)}</span>
        <button className="notifiy-popup-wrapper__navigation--link-btn" onClick={handleNavigation}>
          View
        </button>
      </div>
    </div>
  );
};
export default function NotificationPopup({ notifications, setNotifications }: NotsProps) {
  const handleRemoveViewedNotification = (id: string) => {
    setNotifications((prev) =>
      prev?.filter((notification) => notification.id !== id)
    );
  };
  return (
    <div className="notify-popup-container">
      <div className="notifiy-scroll">
        {notifications?.map((data, index) => (
          <NotificationDetail
            id={data.id}
            title={data.title}
            text={data.text}
            link={data.link}
            createdOn={data.createdOn}
            key={data.id || index}
            onRemoveViewedNotification={handleRemoveViewedNotification}
          />
        ))}
      </div>
    </div>
  );
}
