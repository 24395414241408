import { SetStateAction, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { z } from "zod";
import { Button } from "components/ui/button/Button";
import { otp } from "utilities/formSchemas";
import "./authentication.scss";
import { usePostOtpValidationMutation } from "./authenticationService";
import { useDispatch, useSelector } from "react-redux";
import {
  getAuthOtpDetails,
  getResendCodeDeviceId,
} from "pages/login/loginSlice";
import { setCredentials, setIsLogin } from "features/authSlice";
import { useRedirect } from "hooks/useRedirect";
import { usePostMfaMutation } from "pages/MFA/mfaService";
import Overlay from "components/ui/loading-spinners/Overlay/Overlay";
import { ErrorType } from "utilities/error";
import {
  InvalidOTPError,
  LoginError,
  OTPExpiredError,
} from "utilities/Constant";
import { setFSUserProperties } from "utilities/full-story";
import { getLastName } from "utilities/string";
import { useNavigate } from "react-router-dom";

interface FormData {
  otp: string;
}

const formSchema = z.object({ otp });

export default function MFA() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const otpDetails = useSelector(getAuthOtpDetails);
  useRedirect(otpDetails.id == "", "/");
  const [error, setError] = useState({ status: 0, code: "" });
  const {
    handleSubmit,
    control,
    clearErrors,
    watch,
    formState: { errors, isSubmitted },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onSubmit", // Only validate on submit
    reValidateMode: "onSubmit", // Re-validate only on submit
  });

  const { otp } = watch();
  const [sendRequest, { isSuccess, data, isLoading, isError: isOtpError }] =
    usePostOtpValidationMutation();
  const [resendCode] = usePostMfaMutation();
  const onSubmit = (value: string) => {
    return sendRequest({
      otp: value,
      flowId: otpDetails.id,
    })
      .unwrap()
      .catch((error: ErrorType) => {
        setError((prev) => ({
          ...prev,
          status: error.status,
          code: error.data.code.toLowerCase(),
        }));
      });
  };

  if (isSuccess && data) {
    const lastName = getLastName(data.name);
    setFSUserProperties(undefined, lastName.toLowerCase());
    dispatch(setCredentials(data));
    dispatch(setIsLogin(true));
    navigate("/post-auth", { replace: true });
  }

  let ErrorMessage = "";

  if (isOtpError && error.status == 400) {
    if (error.code == "invalid otp") {
      ErrorMessage = InvalidOTPError;
    } else {
      ErrorMessage = OTPExpiredError;
    }
  }
  const resendDeviceId = useSelector(getResendCodeDeviceId);

  const handleResendCode = () => {
    return resendCode({
      id: resendDeviceId,
      flowId: otpDetails.id,
    });
  };
  if (isLoading) return <Overlay />;
  return (
    <div className="account">
      <form
        onSubmit={handleSubmit((data) => {
          return onSubmit(data.otp);
        })}
        className="account-form"
      >
        <div className="account-form__title">Please verify your account</div>
        <div className="account-form__digit">Enter your 6-digit code below</div>
        {isOtpError &&
          (error.status == 400 ? (
            <p className="error__message1">{ErrorMessage}</p>
          ) : (
            <div className="error__container">
              {LoginError.map((error) => {
                return (
                  <p key={error} className="error__message2">
                    {error}
                  </p>
                );
              })}
            </div>
          ))}
        {!ErrorMessage && isSubmitted && errors.otp && (
          <p className="error__message3">{errors.otp.message}</p>
        )}
        <div>
          <Controller
            name="otp"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <div className="account-form--input">
                  <OtpInput
                    inputStyle="account-form--input-field"
                    onChange={(e: SetStateAction<string>) => {
                      onChange(e);
                      if (isSubmitted) {
                        clearErrors("otp");
                      }
                    }}
                    value={value}
                    numInputs={6}
                    isInputNum
                    separator={<span className="separator">&mdash;</span>}
                    containerStyle="account-form--container-field"
                  />
                </div>
              );
            }}
          />
        </div>
        <Button title="Continue" size="large" className="account-form__button1" />
      </form>
      <a onClick={() => handleResendCode()} className="link">
              Resend Code
            </a>
    </div>
  );
}
