export const calculateNotificationDuration = (createdOn: Date): string | undefined => {
    const difference = new Date().getTime() - new Date(createdOn).getTime(); // Use getTime() for accurate subtraction
    const totalDays = Math.floor(difference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
  
    if (totalDays === 0) {
     
      return "Today";
    } else if (totalDays > 0 && totalDays <= 30) {
      return `${totalDays} day${totalDays > 1 ? "s" : ""} ago`;
    } else if (totalDays > 30 && totalDays <= 365) {
      const months = Math.floor(totalDays / 30);
      return `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (totalDays > 365) {
      const years = Math.floor(totalDays / 365);
      return `${years} year${years > 1 ? "s" : ""} ago`;
    }
  };
