import { useState, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { z } from "zod";
import Modal from "components/modal/Modal";
import { Button } from "components/ui/button/Button";
import Form from "components/ui/form/Form";
import { FormInput } from "components/ui/form/FormInput";
import { LoadingOverlay } from "components/ui/loading-spinners";
import { LeadProvider, RegisterData, RegistrationRoleType, useGetDecryptUserQuery, usePostRegistrationMutation } from "./registrationService";
import * as Constant from "utilities/Constant";
import { emailAddress, phone, password_input, firstname, lastname, loanpurpose } from "utilities/formSchemas";
import "./registration.scss";
import { useDispatch } from "react-redux";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { setCredentials, setInquiryId, setLeadProvider, setUserId } from "features/authSlice";
import { ErrorType } from "utilities/error";
import { Consent } from "components/consent/Consent";
import useDesktopChecker from "hooks/useDesktopChecker";
import { setFSUserProperties } from "utilities/full-story";
import PasswordChecker from "components/password-and-confirm-password/passwordcheckerversion2/PasswordCheckerVersion2";
import homeGraphic from "assets/images/HomeGraphic.png";
import { setResendCodeDeviceId } from "pages/login/loginSlice";

const formSchema = z.object({
  email: emailAddress,
  firstname,
  lastname,
  phone,
  loanpurpose,
  password_input,
});

export default function Registration() {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors, dirtyFields },
    setValue,
  } = useForm({
    resolver: zodResolver(formSchema),
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isDesktop = useDesktopChecker();
  const encryptedUser = searchParams.get("t")!;
  const email = searchParams.get("email")!;
  const loanId = searchParams.get("loanId")!;
  const leadProviderName = searchParams.get("lpn")!;
  const isEncryptedUser = !!encryptedUser;
  const isBonzoUrlParams = !!(email && loanId && leadProviderName);

  const {
    data: decryptedUserDetails,
    isLoading,
    isFetching,
  } = useGetDecryptUserQuery({ encryptedUser, email, loanId, leadProviderName }, { skip: !(isEncryptedUser || isBonzoUrlParams) });

  useEffect(() => {
    if (decryptedUserDetails) {
      const { ex, fn, ln, em, ph, lp, los, id, lpn, rt } = decryptedUserDetails;
      if (!ex) {
        if (los) {
          dispatch(setInquiryId(id));
        }
        if (lpn) {
          dispatch(setLeadProvider(lpn));
        }
        setIsBtnDisabled((prevState) => !prevState);
        setValue("firstname", fn);
        setValue("lastname", ln);
        setValue("email", em);
        setValue("phone", ph.replace(/-/g, ""));
        setValue("loanpurpose", lp);
        const isBonzoSpousalCoBorrower =
          lpn?.toLowerCase() === LeadProvider.Bonzo.toLowerCase() && rt?.toLowerCase() === RegistrationRoleType.SpousalCoBorrower.toLowerCase();
        setIsBonzoSpouse(isBonzoSpousalCoBorrower);
      } else {
        navigate("/", { replace: true, state: decryptedUserDetails });
      }
    }
  }, [decryptedUserDetails]);

  let showModal = false;
  if (!isLoading && !isFetching && !decryptedUserDetails) {
    showModal = true;
  } else {
    setFSUserProperties(decryptedUserDetails?.em ?? "", decryptedUserDetails?.ln.toLowerCase() ?? "");
  }

  const [error, setError] = useState(0);
  const [hasPwdError, setHasPwdError] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [isBonzoSpouse, setIsBonzoSpouse] = useState(false);
  const [sendRequest, { isSuccess, data, isLoading: isRegistrationLoading }] = usePostRegistrationMutation();
  const dispatch = useDispatch();
  const onSubmit = (data: RegisterData) => {
    if (!hasErrors && !hasPwdError) {
      sendRequest(data)
        .unwrap()
        .catch((error: ErrorType) => {
          if (error.status == 409) {
            navigate("/", { replace: true });
          } else {
            setError(500);
          }
        });
    }
  };
  if (isSuccess && data) {
    if (isBonzoSpouse) {
      dispatch(setUserId(data.id));
      dispatch(setResendCodeDeviceId(data.deviceAuthId));
      navigate("/registrationAuth", { replace: true });
    } else {
      const hasLosFile = data.hasLosLoanNumber;
      dispatch(
        setCredentials({
          ...data,
          applicationId: "",
          applicationStatus: "",
          hasLosFile,
        })
      );
      navigate("/post-auth", { replace: true });
    }
  }
  const ispasswordFieldModified = (dirtyFields.password_input as boolean) || false;
  const hasErrors = !!Object.keys(errors).length;

  if (isRegistrationLoading) return <LoadingOverlay text="Registering..." />;
  const formatContent = (content: string[]) => {
    return (
      <ul className="registration-list">
        {content?.map((line) => (
          <li key={line}>{line}</li>
        ))}
      </ul>
    );
  };
  return (
    <>
      {(isLoading || isFetching) && <LoadingOverlay text="Loading user details..." />}
      <div className="registration">
        {showModal && (
          <Modal
            defaultBody
            defaultBodyLabel="Something went wrong!"
            defaultBodyLabelIcon={regular("triangle-exclamation")}
            defaultBodySubContent={[
              "Please open the email we sent you and click on the link so that we can try again.",
              "We apologize for the inconvenience.",
            ]}
            footer={<></>}
          />
        )}
        <div className="registration__container">
          <div className="registration__form">
            <h1 className="registration__header">{isDesktop ? `Create an account to continue your application` : `Create an account to continue`}</h1>
            {error == 500 && (
              <div className="registration__error">
                {isDesktop
                  ? Constant.RegistrationError
                  : Constant.RegistrationError.map((error) => {
                      return <p key={error}>{error}</p>;
                    })}
              </div>
            )}
            <Form
              onSubmit={handleSubmit((data) => {
                onSubmit({
                  emailaddress: data.email as string,
                  password: data.password_input as string,
                  firstname: data.firstname as string,
                  lastname: data.lastname as string,
                  loanPurpose: data.loanpurpose as string,
                  phoneNumber: data.phone as string,
                  referralSource: "",
                  referredBy: "someone",
                  inquiryId: decryptedUserDetails?.id,
                  leadProvider: decryptedUserDetails?.lpn,
                  nhDealApplicationId: decryptedUserDetails?.nhDealAppId,
                  userRole: isBonzoSpouse ? decryptedUserDetails?.rt : RegistrationRoleType.Borrower,
                });
              })}
            >
              <div className="row--item">
                <FormInput
                  formHandle={register("email")}
                  inputProps={{
                    name: "email",
                    placeholder: "Enter Your Email Address",
                    label: "Email Address",
                    errorMessage: errors.email?.message as string,
                    isReadOnly: true,
                  }}
                />
              </div>
              <div className={isBonzoSpouse ? "row--hidden" : "row--item"}>
                <FormInput
                  formHandle={register("phone")}
                  inputProps={{
                    name: "phone",
                    placeholder: "Enter Your Phone Number",
                    label: "Phone Number",
                    errorMessage: errors.phone?.message as string,
                    isReadOnly: true,
                  }}
                />
              </div>
              <div className="row--item">
                <Controller
                  name="password_input"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <PasswordChecker
                      onChange={onChange}
                      checkErrors={ispasswordFieldModified}
                      createLabel="Create Password"
                      setHasPasswordError={setHasPwdError}
                      setBtnVisible={setIsBtnDisabled}
                      createPasswordPlaceholderName="Create password"
                    />
                  )}
                />
              </div>
              <Button title="Continue" className="registration__button" disabled={isBtnDisabled} />
            </Form>
          </div>
          {isDesktop && (
            <div className="registration__information">
              <p className="registration__text1">Better Path to Homeownership</p>
              <p className="registration__text2">Seamless, Secure, and Built for You</p>
              <p className="registration__text3">{formatContent(Constant.registrationInformation)}</p>
              <img src={homeGraphic} alt="login-graphic" className="registration__graphic" />
            </div>
          )}
        </div>
      </div>
      <Consent
        termsOfUseLink={Constant.TERMSOFUSELINK}
        privacyLink={Constant.PRIVACYLINK}
        partnersLink={Constant.PARTNERSLINK}
        agreementLink={Constant.AGREEMENTLINK}
      />
    </>
  );
}
